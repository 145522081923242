<template>
  <div class="agree-page">
    <person-head></person-head>
    <div class="agree_main">
      <div class="agree_pos">
        <router-link to="">线上签约</router-link>
        <span> > </span>
        <router-link class="agree_a" to="">签约详情</router-link>
      </div>
      <!--agree-body start-->
      <div class="record-body">
        <ul class="personage_ul">
          <li>
            <div class="personage_ul_img m-r-20" v-if="details.fang">
              <img :src="details.fang.fang_fengmian_image" alt="" />
              <div class="personage_img_marking">
                {{ details.fang.fang_level }}
              </div>
            </div>
            <div class="personage_ul_main f-1">
              <div class="personage_ul_name">
                {{ details.fang ? details.fang.name : "" }}
              </div>
              <div class="personage_ul_title">
                {{ details.fang ? details.fang.fang_xing_copy : "" }} |
                {{ details.fang ? details.fang.fang_mianji : "" }}㎡ |
                {{ details.fang ? details.fang.j_name : "" }}
              </div>
              <div class="personage_ul_tags" v-if="details.fang">
                <span class="tag" v-for="(item, index) in details.fang.fang_biaoqian" :key="index">{{ item }}</span>
              </div>
              <div class="personage_ul_price flex-between">
                <div class="flex">
                  <div class="personage_ul_price_left m-r-20">
                    <span>{{
                      details.fang ? details.fang.can_price : ""
                    }}元/月</span>
                  </div>
                </div>
                <div class="personage_ul-bot">
                  <button class="rate_page_btn red" v-if="details.status == 5" @click="termination">
                    终止合作
                  </button>
                  <!-- <button
                    class="rate_page_btn blue m-r-15"
                    @click="$refs.upload.open()"
                  >
                    上传信息
                  </button>
                  <button class="rate_page_btn blue" @click="$refs.sign.open()">
                    成交报告
                  </button> -->
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!--agree_detail start-->
        <div class="agree_detail">
          <!--agree_detail_box start-->
          <div class="agree_detail_box">
            <div class="agree_detail_tit">客户信息</div>
            <div class="agree_detail_item">
              <div class="li w33">
                <span class="color-6">客户姓名：</span> {{ details.kehu_name }}
              </div>
              <div class="li w33">
                <span class="color-6"> 电话：</span> {{ details.kehu_mobile }}
              </div>
            </div>
          </div>
          <!--agree_detail_box end-->
          <!--agree_detail_box start-->
          <div class="agree_detail_box">
            <div class="agree_detail_tit">业主信息</div>
            <div class="agree_detail_item">
              <div class="li w33">
                <span class="color-6">业主姓名：</span>
                {{ details.fang ? details.fang.yezhu_name : "" }}
              </div>
              <div class="li w33">
                <span class="color-6"> 电话：</span>
                {{ details.fang ? details.fang.yezhu_mobile : "" }}
              </div>
            </div>
          </div>
          <!--agree_detail_box end-->
          <!--agree_detail_box start-->
          <div class="agree_detail_box">
            <div class="agree_detail_tit">其他信息</div>
            <div class="agree_detail_item">
              <div class="li w50">
                <span class="color-6">合同起始时间：</span>{{ details.start_time }}
              </div>
              <div class="li w50">
                <span class="color-6"> 合同到期时间：</span>{{ details.end_time }}
              </div>
              <div class="li w50">
                <span class="color-6"> 成交价格：</span>
                <span style="color: #ff2121">{{ details.price }}元/月</span>
              </div>
              <template v-if="details.status != 2 && details.status != 5">
                <div class="li w50">
                  <span class="color-6">姓名：</span>{{ details.account_name }}
                </div>
                <div class="li w50">
                  <span class="color-6">开户行：</span>{{ details.account_bank }}
                </div>
                <div class="li w50">
                  <span class="color-6">收款账号：</span>{{ details.account }}
                </div>
                <div class="li w100" style="display: flex">
                  <div class="color-6" style="line-height: 30px;flex-shrink: 0;">
                    收款账号图片：
                  </div>
                  <div class="" style="display: flex; align-items: center;flex-wrap: wrap;">
                    <div class="" v-for="(item, index) in accImageList" :key="index" style="margin: 0 20px 20px 0;">
                      <!-- <img
                        :src="item"
                        style="width: 120px; height: 100px; border-radius: 4px"
                        alt=""
                      /> -->
                      <el-image style="width: 120px; height: 100px;border-radius: 4px;" :src="item"
                        :preview-src-list="accImageList">
                      </el-image>
                    </div>
                  </div>
                </div>
                <div class="li w100" style="display: flex">
                  <div class="color-6" style="line-height: 30px;flex-shrink: 0;">
                    签约资料：
                  </div>
                  <div class="" style="display: flex; align-items: center;flex-wrap: wrap;">
                    <div class="" v-for="(item, index) in qianZiliaoImageList" :key="index"
                      style="margin: 0 20px 20px 0;">
                      <el-image style="width: 120px; height: 100px;border-radius: 4px;" :src="item"
                        :preview-src-list="qianZiliaoImageList">
                      </el-image>
                    </div>
                  </div>
                </div>
                <div class="li w100">
                  <span class="color-6">备注：</span>{{ details.ye_text }}
                </div>
              </template>
            </div>
          </div>
          <!--agree_detail_box end-->
          <!--agree_detail_box start-->
          <div v-if="[5,6,7,0,1].includes(details.status)" class="agree_detail_box" style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            ">
            <div class="agree_detail_tit">合同信息</div>
            <div @click="openDocument(5)" class="color-6" style="cursor: pointer">点击查看>></div>
          </div>
          <!--agree_detail_box end-->
          <!--agree_detail_box start-->
          <div class="agree_detail_box" v-if="[5,6,7,0,1].includes(details.status)">
            <div class="agree_detail_tit">合同附属信息</div>
            <div class="flexs agree_detail_list">
              <div class="m-r-10 agree_detail_li" @click="openDocument(1)">
                风险告知书
              </div>
              <div class="m-r-10 agree_detail_li" @click="openDocument(2)">
                房屋交接清单
              </div>
              <div class="m-r-10 agree_detail_li" @click="openDocument(3)">
                佣金确认书房东
              </div>
              <div class="agree_detail_li" @click="openDocument(4)">
                佣金确认书租客
              </div>
            </div>
          </div>
          <!--agree_detail_box end-->
          <!--agree_detail_box start-->
          <div class="agree_detail_box" v-if="details.status != 2 && details.status != 5 && details.status != 6">
            <div class="agree_detail_tit">打款凭证</div>
            <div class="agree_detail_item fl">
              <!-- <img style="
                  width: 240px;
                  height: 150px;
                  border-radius: 4px;
                  margin: 0 20px 20px 0;
                " v-for="(item, index) in details.sf_image" :key="index" :src="item" /> -->
              <el-image style="width: 240px;
                  height: 150px;
                  border-radius: 4px;
                  margin: 0 20px 20px 0;" :src="item" v-for="(item, index) in details.sf_image" :key="index"
                :preview-src-list="details.sf_image">
              </el-image>
            </div>
            <div class="agree_detail_item fl">
              <!-- <img style="
                  width: 240px;
                  height: 150px;
                  border-radius: 4px;
                  margin: 0 20px 20px 0;
                " v-for="(item, index) in dakuanImageList" :key="index" :src="item" /> -->
              <el-image style="width: 240px;
                  height: 150px;
                  border-radius: 4px;
                  margin: 0 20px 20px 0;" :src="item" v-for="(item, index) in dakuanImageList" :key="index"
                :preview-src-list="dakuanImageList">
              </el-image>
            </div>
            <div class="">
              <span class="color-6">备注：</span>{{ details.text }}
            </div>
          </div>
          <div class="agree_detail_box" v-if="[0, 1].includes(details.status)">
            <div class="agree_detail_tit">收款收据</div>
            <div class="agree_detail_item fl">
              <el-image style="width: 240px;
                  height: 150px;
                  border-radius: 4px;
                  margin: 0 20px 20px 0;" :src="item" v-for="(item, index) in shoiujuList" :key="index"
                :preview-src-list="shoiujuList">
              </el-image>
              <!-- <img style="
                  width: 240px;
                  height: 150px;
                  border-radius: 4px;
                  margin: 0 20px 20px 0;
                " v-for="(item, index) in shoiujuList" :key="index" :src="item" /> -->
            </div>
            <div class="">
              <span class="color-6">备注：</span>{{ details.ye_text_copy }}
            </div>
          </div>
          <div class="agree_detail_box" v-if="details.baogaos">
            <div class="agree_detail_tit">成交报告</div>
            <div class="agree_detail_item">
              <div class="li w50">
                <span class="color-6">合同编号：</span>{{ details.baogaos.contract_number }}
              </div>
              <div class="li w50">
                <span class="color-6">店铺：</span>{{ details.baogaos.uuser.dian_name }}
              </div>
              <div class="li w50">
                <span class="color-6">成交价格：</span>{{ details.baogaos.price }}
              </div>
              <div class="li w50">
                <span class="color-6">收佣：</span>{{ details.baogaos.yong_price }}
              </div>
              <div class="li w50">
                <span class="color-6">组别：</span>{{ details.baogaos.zubie }}
              </div>
              <div class="li w50">
                <span class="color-6">喜报单数：</span>{{ details.baogaos.xibao }}
              </div>
              <div class="li w50" style="word-wrap:break-word;word-break:break-all;">
                <span class="color-6">备注：</span>{{ details.baogaos.beizhu }}
              </div>
              <div class="li w50">
                <span class="color-6">资料是否齐全：</span>{{ details.baogaos.ziliao }}
              </div>
              <div class="li w100">
                <div class="" style="display: flex; flex-wrap: wrap">
                  <div v-for="(item, index) in details.baogaos.ziliao_image" class="" style="margin: 0 20px 0 0"
                    :key="index">
                    <el-image style="width: 120px; height: 120px; border-radius: 4px" :src="item"
                      :preview-src-list="details.baogaos.ziliao_image">
                    </el-image>
                  </div>
                </div>
                <div class="">
                  <div class="" style="display: flex; align-items: center; margin-top: 20px"
                    v-for="(item, index) in details.baogaos.chengjiao" :key="index + 'chengjiao'">
                    <div class="" style="width: 30%;">
                      <span class="color-6">成交方：</span>{{ item.user_name }}
                    </div>
                    <div class="" style="width: 30%;">
                      <span class="color-6">比例：</span>{{ item.bi }}%
                    </div>
                    <div class="f-1">
                      <span class="color-6">成交方业绩：</span>{{ item.price }}元
                    </div>
                  </div>
                  <div class="" style="display: flex; align-items: center; margin-top: 20px"
                    v-for="(item, index) in details.baogaos.xinxi" :key="index + 'xinxi'">
                    <div class="" style="width: 30%;">
                      <span class="color-6">信息方：</span>{{ item.user_name }}
                    </div>
                    <div class="" style="width: 30%;">
                      <span class="color-6">比例：</span>{{ item.bi }}%
                    </div>
                    <div class="f-1">
                      <span class="color-6">信息方业绩：</span>{{ item.price }}元
                    </div>
                  </div>
                  <div class="" style="display: flex; align-items: center; margin-top: 20px"
                    v-for="(item, index) in details.baogaos.weihu" :key="index + 'weihu'">
                    <div class="" style="width: 30%;">
                      <span class="color-6">维护方：</span>{{ item.user_name }}
                    </div>
                    <div class="" style="width: 30%;">
                      <span class="color-6">比例：</span>{{ item.bi }}%
                    </div>
                    <div class="f-1">
                      <span class="color-6">维护方业绩：</span>{{ item.price }}元
                    </div>
                  </div>
                  <div class="" style="display: flex; align-items: center; margin-top: 20px"
                    v-for="(item, index) in details.baogaos.img" :key="index + 'img'">
                    <div class="" style="width: 30%;">
                      <span class="color-6">图片方：</span>{{ item.user_name }}
                    </div>
                    <div class="" style="width: 30%;">
                      <span class="color-6">比例：</span>{{ item.bi }}%
                    </div>
                    <div class="f-1">
                      <span class="color-6">图片方业绩：</span>{{ item.price }}元
                    </div>
                  </div>
                  <div class="" style="display: flex; align-items: center; margin-top: 20px"
                    v-for="(item, index) in details.baogaos.yaoshi" :key="index + 'yaoshi'">
                    <div class="" style="width: 30%;">
                      <span class="color-6">钥匙方：</span>{{ item.user_name }}
                    </div>
                    <div class="" style="width: 30%;">
                      <span class="color-6">比例：</span>{{ item.bi }}%
                    </div>
                    <div class="f-1">
                      <span class="color-6">钥匙方业绩：</span>{{ item.price }}元
                    </div>
                  </div>
                  <div class="" style="display: flex; align-items: center; margin-top: 20px"
                    v-for="(item, index) in details.baogaos.weituo" :key="index + 'weituo'">
                    <div class="" style="width: 30%;">
                      <span class="color-6">委托方：</span>{{ item.user_name }}
                    </div>
                    <div class="" style="width: 30%;">
                      <span class="color-6">比例：</span>{{ item.bi }}%
                    </div>
                    <div class="f-1">
                      <span class="color-6">委托方业绩：</span>{{ item.price }}元
                    </div>
                  </div>
                  <div class="" style="display: flex; align-items: center; margin-top: 20px"
                    v-for="(item, index) in details.baogaos.qita" :key="index + 'qita'">
                    <div class="" style="width: 30%;">
                      <span class="color-6">其他方：</span>{{ item.user_name }}
                    </div>
                    <div class="" style="width: 30%;">
                      <span class="color-6">比例：</span>{{ item.bi }}%
                    </div>
                    <div class="f-1">
                      <span class="color-6">其他方业绩：</span>{{ item.price }}元
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--agree_detail_box start-->
          <div class="agree_detail_box">
            <div class="agree_detail_tit">订单信息</div>
            <div class="agree_detail_item">
              <div class="li w50">
                <span class="color-6">发起时间：</span>{{ details.createtime }}
              </div>
              <div class="li w50" v-if="details.status == 2">
                <span class="color-6">{{ details.status != 2 ? '签约' : '失败' }}时间：</span>{{ details.updatetime }}
              </div>
            </div>
          </div>
          <!--agree_detail_box end-->
        </div>
        <!--agree_detail end-->
      </div>
    </div>
    <!-- 上传弹出 S -->
    <!-- <upload-pop ref="upload"></upload-pop> -->
    <!--上传弹出 E -->
    <!-- 提交合同弹出 S -->
    <!-- <sign-pop ref="sign"></sign-pop> -->
    <!--提交合同弹出 E -->
  </div>
</template>

<script>
import PersonHead from "../../components/PersonHead";
import UploadPop from "../../components/UploadPop";
import SignPop from "../../components/SignPop";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { PersonHead, UploadPop, SignPop },
  data() {
    return {
      from: {
        id: null, //
        fang_type: 1, //房源类型：1=出售2=出租(新房默认1
        fang_types: 1, //1=新房2=住宅3=公寓4=写字楼5=商铺6=别墅
      },
      details: {}, //详情
      accImageList: [],
      shoiujuList: [],//
      qianZiliaoImageList: [],//签约资料
      dakuanImageList: [],//打款凭证
    };
  },
  methods: {
    //打开文件
    openDocument(index) {
      let url = "";
      this.$axios.fileLoks({id:this.from.id,type:6}).then(res=>{
        if (index == 1) {
        url = res.data.fengxian_id_file;
      } else if (index == 2) {
        url = res.data.fang_jiao_id_file;
      } else if (index == 3) {
        url = res.data.jin_chu_id_file;
      } else if (index == 4) {
        url = res.data.jin_zu_id_file;
      } else if (index == 5) {
        url = res.data.contract_image;
      }
      window.open(
        "https://view.officeapps.live.com/op/view.aspx?src=" +
        encodeURIComponent(url)
      );
      })
      
    },
    //终止合作
    termination() {
      this.$axios.termination({ id: this.from.id }).then((res) => {
        this.$message({
          message: res.msg,
          type: "success",
        });
        setTimeout(() => {
          this.$router.go(-1);
        }, 800);
      });
    },
    getDetails() {
      this.$axios.SignAContractDetail(this.from).then((res) => {
        this.details = res.data;
        this.accImageList = res.data.acc_image
          ? res.data.acc_image.split(",")
          : [];
        this.qianZiliaoImageList = res.data.qian_ziliao_image ? res.data.qian_ziliao_image.split(',') : []
        this.dakuanImageList = res.data.dakuan_image ? res.data.dakuan_image.split(',') : []
        this.shoiujuList = res.data.shoiuju ? res.data.shoiuju.split(',') : []
      });
    },
  },
  created() {
    this.from.id = this.$route.query.id;
    this.from.fang_type = this.$route.query.fang_type;
    this.from.fang_types = this.$route.query.fang_types;
    this.getDetails();
  },
};
</script>

<style scoped lang="less">
.rate_page_btn {
  width: 112px;
  height: 40px;
  border: 1px solid #3273f6;
  border-radius: 4px;
  color: #3273f6;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  background: #fff;
  outline: none;
  cursor: pointer;
}

.rate_page_btn.orange {
  background: #ffa740;
  border: 1px solid #ffa740;
  color: #fff;
}

.rate_page_btn.blue {
  background: #3273f6;
  border: 1px solid #3273f6;
  color: #fff;
}

.rate_page_btn.red {
  background: #fddfdf;
  border: 1px solid #fddfdf;
  color: #ff5151;
}

.key_page_select {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;

  .select_item {
    display: flex;
    font-size: 14px;
    color: #666;
    align-items: center;
    padding: 10px 0px;
    margin: 0 10px;
  }

  .label {
    margin-right: 10px;
    white-space: nowrap;
    width: 80px;
    text-align: right;
  }

  .select {
    width: 200px;
  }

  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}

.agree_a {
  color: #3273f6;
  cursor: pointer;
}

/deep/.key_page_select .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 200px;
}

.agree_main {
  background: #fff;
  padding-bottom: 30px;
}

.agree-head {
  margin: 0 20px;
  padding: 20px 0px;
  border-bottom: 1px solid #eaeaea;
}

.agree_pos {
  padding: 30px 30px;
  color: #666;
}

.agree_detail {
  background: #f8fafe;
  margin: 0 50px;
  padding: 30px;

  .agree_detail_tit {
    font-size: 18px;
    position: relative;
    padding-left: 10px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .agree_detail_tit:before {
    width: 4px;
    height: 20px;
    background: #3273f6;
    position: absolute;
    left: 0px;
    top: 50%;
    margin-top: -10px;
    display: block;
    content: "";
  }

  .agree_detail_item {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    padding-left: 10px;
  }

  .w33 {
    width: 30%;
  }

  .w50 {
    width: 50%;
  }

  .w100 {
    width: 100%;
  }

  .li {
    line-height: 30px;
  }

  .agree_detail_box {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .agree_detail_list {
    .agree_detail_li {
      width: 121px;
      height: 33px;
      color: #3273f6;
      cursor: pointer;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #0253f7;
    }
  }

  .img {
    width: 120px;
    height: 120px;
    margin: 10px;
  }
}

.personage_ul_tags {
  display: flex;
  margin: 0 -5px;
  flex-wrap: wrap;
  .tag {
    background: #f5f5f5;
    height: 28px;
    border-radius: 3px;
    padding: 0 20px;
    margin: 5px;
    font-size: 14px;
    color: #999;
    line-height: 28px;
  }
}

.personage_ul {
  li {
    padding: 0px 50px 30px 50px;
    display: flex;

    .personage_ul_img {
      img {
        width: 220px;
        height: 165px;
        border-radius: 8px;
      }

      position: relative;

      .personage_img_marking {
        top: 0;
        left: 0;
        position: absolute;
        width: 56px;
        height: 32px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px 0px 8px 0px;
      }
    }

    .personage_ul_main {
      height: 165px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .personage_ul_name {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
      }

      .personage_ul_title {
        color: #999999;
        font-size: 14px;
      }

      .personage_ul_price {
        display: flex;
        align-items: flex-end;

        .personage_ul_price_left {
          display: flex;
          align-items: center;

          span {
            color: #ff2121;
            font-size: 22px;
            font-weight: bold;
          }
        }

        .personage_ul_price_right {
          display: flex;
          align-items: center;

          span {
            color: #999999;
            font-size: 18px;
          }
        }

        img {
          width: 11px;
          height: 14px;
          margin-left: 10px;
        }
      }

      .personage_ul_footer {
        width: 100%;
        align-items: flex-end;

        .personage_ul_footer_phone {
          width: 36px;
          height: 36px;
          background: #ffe7e3;
          border-radius: 50%;
        }

        .personage_ul_footer_img {
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }

          margin-right: 10px;
        }

        .personage_ul_footer_main {
          height: 48px;
          align-items: flex-start;
          flex-direction: column;
        }

        .personage_ul_footer_name {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }

        .personage_ul_footer_time {
          span {
            color: #ff9600;
            font-size: 14px;

            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}
</style>
